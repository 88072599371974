import { DatePicker, Button, Table, Modal, TimePicker } from "antd";
import React, { useEffect, useState } from "react";
import Home from "../Home";
import axios from "axios";
import appConfig from "../../helpers/appConfig";
import moment from "moment";
import Layout from "../Home";
import { Row, Col } from "react-bootstrap";
import Badge from "react-bootstrap/Badge";
import Form from "react-bootstrap/Form";

const { MonthPicker } = DatePicker;

const index = (props) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalDocs, setTotalDocs] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isShowConfirm, setIsShowConfirm] = useState(false);
  const [id, setId] = useState("");

  const [reservedtabledata, setReservedTableData] = useState([]);
  const [tableNumber, setTableNumber] = useState(1);
  const [reservationTime, setReservationTime] = useState("11:00");

  const [dateRange, setDateRange] = useState();
  const [rangeType, setRangeType] = useState("d");

  useEffect(() => {
    getData();
  }, [page, pageSize, dateRange]);

  useEffect(() => {
    //setReservationTime();
  }, [reservedtabledata, reservationTime]);

  const handleFilter = (date, range) => {
    let editedDate = "";
    let type = range == "m" ? "m" : "d";
    if (date) {
      editedDate = new Date(date);
    }
    console.log(editedDate);
    setDateRange(editedDate);
    setRangeType(type);
  };

  const handleTimeChange = (time, timeString) => {
    console.log("---Time---", time, timeString);
    setReservationTime(timeString);
    console.log("---Changed time is:---", reservationTime);
  };

  ///Get info from data state, used to set time in timepicker
  const getLocalData = (id) => {
    let i = data.findIndex((item) => item._id === id);
    setReservationTime(data[i].time);
    console.log(data[i].time);
  };

  ///Get Reserved table details by reservation id
  const getInfo = async (reservation_id) => {
    console.log("--Selected Reservation id---", reservation_id);

    const res = await axios.post(
      `${appConfig.endpoint}/api/v1/public/getreservedtable`,
      {
        reservationId: reservation_id
      }
    );

    console.log("---Resevered Table Id---", res.data);

    if (res.status == 200) {
      setReservedTableData(res.data.data);
      //setReservationTime(res.data.data.time);
      console.log("---Time from server", reservationTime);
    } else {
    }
  };

  //Get all data
  const getData = async () => {
    let formData = {
      page: page,
      pageSize: pageSize
    };

    if (dateRange && dateRange !== null && dateRange != "") {
      console.log(dateRange);
      formData = { ...formData, date: dateRange, rangeType: rangeType };
    }

    console.log("called");
    setIsLoading(true);

    const res = await axios.get(
      `${appConfig.endpoint}/api/v1/public/getallreservedtables`,
      {
        // params: {
        //   page: page,
        //   pageSize: pageSize
        // }
        params: formData
      },
      {}
    );
    console.log("res", res);
    if (res) {
      setData(res.data.data.docs);
      setTotalDocs(res.data.data.totalDocs);
      setIsLoading(false);
    }
  };

  const handleOk = async () => {
    console.log("called");

    let formData = {
      id: id,
      status: "CONFIRM",
      tableNumber: tableNumber,
      time: reservationTime
    };

    console.log("----Formdata is ----", formData);
    //return;

    const res = await axios.post(
      `${appConfig.endpoint}/api/v1/public/confirmorcancelreservedtable`,
      formData
      // {
      //   // id: id,
      //   // status: "CONFIRM",
      //   // tableNumber: tableNumber,
      //   // time: reservationTime
      // }
    );
    console.log("ressss", res);

    getData();
    setIsShowConfirm(false);
  };

  const handleCancel = async () => {
    console.log("called");
    const res = await axios.post(
      `${appConfig.endpoint}/api/v1/public/confirmorcancelreservedtable`,
      {
        id: id,
        status: "CANCEL"
      }
    );
    console.log("ressss", res);

    getData();
    setIsShowConfirm(false);
  };

  const handleClose = () => {
    setTableNumber("");
    //setReservationTime('11:00');
    setIsShowConfirm(false);
  };

  const columns = [
    {
      title: "Table #",
      key: "table",
      render: (text, record) => {
        return <b>{record.tableNumber}</b>;
      }
    },
    {
      title: "Name",
      key: "firstName",
      render: (text, record) => {
        return <b>{record.firstName + " " + record.lastName}</b>;
      }
    },
    {
      title: "Phone",
      key: "phone",
      render: (text, record) => {
        return <b>{record.phoneNumber}</b>;
      }
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text, record) => {
        return moment(record.date).format("DD/MM/YYYY");
      }
    },
    {
      title: "time",
      dataIndex: "time",
      key: "time"
    },
    {
      title: "Status",
      dataIndex: "reserveStatus",
      key: "reserveStatus",
      render: (text, record) => {
        let badgeStyle = "secondary";

        switch (record.reserveStatus) {
          case "PENDING":
            badgeStyle = "warning";
            break;

          case "CONFIRMED":
            badgeStyle = "success";
            break;

          case "CANCELLED":
            badgeStyle = "light";
            break;

          default:
            badgeStyle = "warning";
        }

        return (
          <Badge pill bg={badgeStyle}>
            {record.reserveStatus}
          </Badge>
        );
      }
    },
    {
      title: "Adult(s)",
      dataIndex: "numberOfSeats",
      key: "numberOfSeats"
    },
    {
      title: "Children(s)",
      dataIndex: "numberOfSeatsChildren",
      key: "numberOfSeatsChildren"
    },
    {
      title: "Note",
      dataIndex: "note",
      key: "note"
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        //console.log(text, "text", record);
        return (
          text.reserveStatus == "PENDING" && (
            <Button
              onClick={() => {
                setIsShowConfirm(true);
                setId(record._id);
                getLocalData(record._id);
                getInfo(record._id);
              }}
            >
              {/* <i className="fa fa-eye"></i> */}Confirm
            </Button>
          )
        );
      }
    }
  ];
  return (
    <Layout>
      <Row className="report-container ">
        <span className="item">
          <span id="less-visible">Opprett konto / </span>
          Reserve Table
        </span>
      </Row>

      <Row className="pb-3">
        <Col span={4}>
          <DatePicker onChange={(e) => handleFilter(e, "d")} />
          <MonthPicker
            placeholder="Select Month"
            onChange={(e) => handleFilter(e, "m")}
          />
        </Col>
      </Row>

      <Table
        bordered
        loading={isLoading}
        dataSource={data}
        columns={columns}
        pagination={{
          total: totalDocs,
          current: page,
          pageSize: pageSize,
          onChange: (page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
          }
        }}
      />
      {isShowConfirm && (
        <Modal
          visible={isShowConfirm}
          title="Confirm Reservation"
          onOk={handleOk}
          centered
          onCancel={handleClose}
          footer={[
            <Button key="confirm" type="primary" onClick={handleOk}>
              Confirm
            </Button>,
            <Button key="submit" onClick={handleCancel}>
              Cancel Booking
            </Button>,
            <Button key="back" onClick={handleClose}>
              Close
            </Button>
          ]}
        >
          <Row className="mb-3">
            <Col xs={6}>Customer Name</Col>
            <Col xs={6}>
              {reservedtabledata.firstName} {reservedtabledata.lastName}
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={6}>Contact</Col>
            <Col xs={6}>{reservedtabledata.phoneNumber}</Col>
          </Row>
          <Row className="mb-3">
            <Col span={12}>Total Person(s)</Col>
            <Col span={12}>
              {Number(reservedtabledata.numberOfSeats) +
                Number(reservedtabledata.numberOfSeatsChildren)}
            </Col>
          </Row>
          <Row className="mb-3">
            <Col span={12}>Timing ({reservedtabledata.time})</Col>
            <Col span={12}>
              {reservedtabledata.time != "" && (
                <TimePicker
                  format="HH:mm"
                  defaultValue={moment(reservationTime, "HH:mm")}
                  onChange={handleTimeChange}
                />
              )}
            </Col>
          </Row>
          <Row className="mb-3">
            <Col span={12}>Table #</Col>
            <Col span={12}>
              <Form.Control
                size="sm"
                type="number"
                value={tableNumber}
                onChange={(txt) => setTableNumber(txt.target.value)}
              />
            </Col>
          </Row>
        </Modal>
      )}
    </Layout>
  );
};

export default index;
