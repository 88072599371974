import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Icon as RIcon } from "react-icons-kit";
import { checkCircle, edit } from "react-icons-kit/fa";
import {
  getInformation,
  updateAvailability,
  updateDayAndTime,
  updateInfo,
  treeDriveAvailability
} from "../../action/informationAction";
import {
  Card,
  ListGroup,
  ListGroupItem,
  Col,
  Row,
  Table,
  Button
} from "react-bootstrap";
import { Divider ,notification} from "antd";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import EditModel from "./EditModel";
import Home from "./../Home";
import { socket } from "../../utils/socketNew";
import {updateReserveSeats} from "../Information/Apis"
const Information = (props) => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.information)
  const bid=localStorage.getItem("bid")
  useEffect(() => {
    props.getInformation(bid);
    socket.on("restAvailability", (socketData) => {
    console.log("socket",socketData)
    console.log("------online availablity on/off----")
    // props.getInformation();
    setIsBranchActive(socketData.availability.status);
    setQrAllowByRest(socketData.isQrAllowByRest)
    setDriveOut(socketData.isRestDriveOut)
    setPickup(socketData.isRestPickup)
    setisTdPickup(socketData.isTreeDrivePickUpAllowed)
    setisTdDriveOut(socketData.isTreeDriveDriveOutAllowed)
    setisTdAllowed(socketData.isTreeDriveAllowed)
    setReserveSeats(socketData.availSeats)
  });
  }, []);
  // socket.on("treeDriveAvailSer", (socketData) => {
  //   console.log("------treedrive socket availablity on/off----")
  //   // console.log("socket",socketData)
  //   props.getInformation();
  // });
  let [isBranchActive, setIsBranchActive] = useState(false);
  let [QrAllowByRest, setQrAllowByRest] = useState(false);
  let [allDays, setAllDays] = useState([]);
  let [show, setShow] = useState(false);
  let [driveOut, setDriveOut] = useState(false);
  let [pickup, setPickup] = useState(false);
  let [isTdAllowed, setisTdAllowed] = useState(false);
  let [isTdDriveOut, setisTdDriveOut] = useState(false);
  let [isTdPickup, setisTdPickup] = useState(false);
  let [reserveSeats, setReserveSeats] = useState(0);
  const {
    name,
    availability,
    sunday,
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday,
    phoneNo,
    email,
    website,
    taxNo,
    accountNumber,
    address,
    description,
    isQrAllow,
    isQrAllowByRest,
    isTreeDriveAllowed,
    isRestaurantAvailable,
    isRestPickup,
    isRestDriveOut,
    isTreeDriveDriveOutAllowed,
    isTreeDrivePickUpAllowed,
    restaurantEmail,
    availSeats
  } = props.details;
  const { successData } = props;
  useEffect(() => {
    if (availability) {
      setIsBranchActive(availability.status);
    }
    setReserveSeats(availSeats)
    setQrAllowByRest(isQrAllowByRest)
    setDriveOut(isRestDriveOut)
    setPickup(isRestPickup)
    setisTdPickup(isTreeDrivePickUpAllowed)
    setisTdDriveOut(isTreeDriveDriveOutAllowed)
    setisTdAllowed(isTreeDriveAllowed)
    if (sunday)
      setAllDays([
        { ...monday, day: "Monday", disabled: false },
        { ...tuesday, day: "Tuesday", disabled: false },
        { ...wednesday, day: "Wednesday", disabled: false },
        { ...thursday, day: "Thursday", disabled: false },
        { ...friday, day: "Friday", disabled: false },
        { ...saturday, day: "Saturday", disabled: false },
        { ...sunday, day: "Sunday", disabled: false }
      ]);
  }, [props.details]);

  const empType = localStorage.getItem("empType");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    if (
      successData &&
      Object.keys(successData).length !== 0 &&
      successData.actionType === "updateInfo"
    ) {
      handleClose();
      props.getInformation();
    }
  }, [successData]);

  const toggleBranchStatus=(e)=>{
    setIsBranchActive(e)
    let data={
      isBranchActive:e,
      QrAllowByRest,
      fieldUpdated:"branchStatus"

    }
    props.updateAvailability(data)
  }

const toggleDriveOut=(e)=>{
  console.log("--------e.targtet.name",e)
  setDriveOut(e)
  let data={
    driveOut:e,
    pickup
  }
  props.treeDriveAvailability(data)
}
const togglePickup=(e)=>{
  console.log("--------e.targtet.name",e)
  setPickup(e)
  let data={
    driveOut,
    pickup:e
  }
  props.treeDriveAvailability(data)
}
  const toggleQrAllowByRest=(e)=>{
    setQrAllowByRest(e)

    let data={
      isBranchActive,
      QrAllowByRest:e,
      fieldUpdated:"isQrAllowByRest"

    }
    props.updateAvailability( data)
  }
const openNotification = (type, message) => {
  notification[type]({
    message: message
  });
};
  const updateSeats=async()=>{
    console.log("first")
    let data= await updateReserveSeats(reserveSeats)
    if(data.status==200){
      setReserveSeats(data.data.availSeats)
      openNotification ("success","Available Seats Updated")
    }else{
      openNotification ("error",data.message)
    }
  }
  const getAllDays = () => {
    return (
      allDays.length &&
      allDays.map((day, i) => {
        if (day)
          return (
            <Col md={3} key={i} className="" style={{ fontSize: "20px" }}>
              <Card key={i}>
                <Card.Body>
                  <p style={{ marginBottom: "0.2em" }}>
                    {day.status ? (
                      <RIcon
                        style={{ color: "green" }}
                        icon={checkCircle}
                        size={18}
                      />
                    ) : (
                      <RIcon
                        style={{ color: "red" }}
                        icon={checkCircle}
                        size={18}
                      />
                    )}{" "}
                    {day.day}
                  </p>
                  {/* <p style={{ margin:"0" }}>Regular</p> */}
                  <p style={{ marginBottom: "0.2em" }}>
                  {day.nightOpening?day.nightOpening:"00:00"}--{day.nightClosing?day.nightClosing:"00:00"}
                  </p>
                  <p style={{ marginBottom: "0.2em" }}>
                   {day.openingTime}--{day.closingTime}
                  </p>
                  {/* <p style={{ margin:"0" }}>Night</p> */}
                </Card.Body>
              </Card>
            </Col>
          );
      })
    );
  };
  return (
    <Home id="info-page">
      <div className="report-container text-center">
        <span className="item ">{name} Detaljer</span>
      </div>
      <Card>
        <ListGroup className="list-group-flush">
          <ListGroupItem>
            {isRestaurantAvailable &&  !isTdAllowed ? <Row marginBottom={10}>
              <Col span={6} className="d-flex align-items-center justify-content-between">
                <h5 className="text-right mb-0">Online Tilgjengelighet hjok:</h5>
                <BootstrapSwitchButton
                  checked={isBranchActive}
                  onChange={(e) =>{toggleBranchStatus(e)}}
                  onlabel="Active"
                  offlabel="Inactive"
                  onstyle="btn-ab"
                  style="btn-ab"
                  width={200}
                />
              </Col>
            </Row> : ""}
              {isTdAllowed ? (
              <>
                <Divider className="mb-0" />
                <Row>
                  <Col
                    span={12}
                    className="d-flex align-items-center justify-content-between pt-3"
                  >
                    <h4 className="text-right mb-0"> Tree Drive: </h4>
                  </Col>
                  {isTdDriveOut ? (
                    <Col
                      span={6}
                      className="d-flex align-items-center justify-content-end pt-3"
                    >
                      <h5 className="text-right mb-0 mr-2"> Drive Out </h5>
                      <div
                        // span={2}
                        className="d-flex align-items-center pt-3 mb-3"
                      >
                        <BootstrapSwitchButton
                          checked={driveOut}
                          name="driveOut"
                          onChange={(e) => {
                            toggleDriveOut(e);
                          }}
                          onlabel="Active"
                          offlabel="Inactive"
                          onstyle="btn-ab"
                          style="btn-ab"
                          width={120}
                        />
                      </div>
                    </Col>
                  ) : (
                    <></>
                  )}

                  {isTdPickup ? (
                    <Col
                      span={6}
                      className="d-flex align-items-center justify-content-end pt-3"
                    >
                      <h5 className="text-right mb-0 mr-2"> Pickup</h5>
                      <div
                        // span={2}
                        className="d-flex align-items-center pt-3 mb-3"
                      >
                        <BootstrapSwitchButton
                          checked={pickup}
                          name="pickup"
                          onChange={(e) => {
                            togglePickup(e);
                          }}
                          onlabel="Active"
                          offlabel="Inactive"
                          onstyle="btn-ab"
                          style="btn-ab"
                          width={120}
                        />
                      </div>
                    </Col>
                  ) : (
                    <></>
                  )}
                </Row>
              </>
            ) : (
              <></>
            )}

            {isQrAllow? 
              
              <Row>
            <Col span={6}  className="d-flex align-items-center justify-content-between pt-3">
                <h5 className="text-right mb-0"> Online Qr Menu </h5>
                <BootstrapSwitchButton
                  checked={QrAllowByRest}
                  onChange={(e) =>{toggleQrAllowByRest(e)}}
                  onlabel="Active"
                  offlabel="Inactive"
                  onstyle="btn-ab"
                  style="btn-ab"
                  width={200}
                />

              </Col>
            </Row>:<></>}
          </ListGroupItem>
          <ListGroupItem>
          <div style={{backgroundColor:"whitesmoke", padding:"8px" ,width:"220px", marginBottom:20}}>

<h5 style={{color:"gray"}}>Available Seats </h5>  

<div>
  <span><input 
    type="number"
    value={reserveSeats}
    style={{width:"80px"}}
    onChange={(e)=>(setReserveSeats(e.target.value))}
    /></span>


    <span><Button 
className="btn-ab"
style={{ width: 60, padding: 0 ,marginLeft:20 }}
onClick={()=>(updateSeats())}
htmlType="submit">
update

</Button></span>
</div>

</div>
          </ListGroupItem>
          <ListGroupItem  >
            <Row className="py-2">
              <Col>
                <h5 className="mb-0">Uke Tilgjengelighet :</h5>
              </Col>
              {empType === "MANAGER" ? (
                <Col>
                  <Button className="btn-ab float-right" style={{width: '200px'}} onClick={handleShow}>
                    <RIcon style={{ color: "#fff" }} icon={edit} size={18} /> Edit
                  </Button>{" "}
                </Col>
              ) : (
                ""
              )}
            </Row>
            <Row>{getAllDays()}</Row>
          </ListGroupItem>
          <ListGroupItem className="border-0">
            <Row className="justify-content-md-center">
              <Col md={12}>
                <Table striped bordered hover>
                  <tbody>
                    <tr>
                      <th>Tel. Nr</th>
                      <td>{phoneNo}</td>
                    </tr>
                    <tr>
                      <th>Restaurant E-Mail</th>
                      <td>{restaurantEmail?restaurantEmail:email}</td>
                    </tr>
                    <tr>
                      <th>Nettsted</th>
                      <td>
                        <a href={website} target="_blank">
                          {website}
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <th>Org. Nr.</th>
                      <td>{taxNo}</td>
                    </tr>
                    <tr>
                      <th>Konto Nr</th>
                      <td>{accountNumber}</td>
                    </tr>
                    <tr>
                      <th>Addresse</th>
                      <td>
                        {address && address.line1 ? address.line1 : ""}, &nbsp;
                        {address && address.line2 ? address.line2 : ""}
                        <br />
                        {address && address.city ? address.city : ""},
                        {address && address.state ? address.state : ""},&nbsp;
                        {address && address.pin ? address.pin : ""},&nbsp;
                        {address && address.country ? address.country : ""}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <b>Om Oss</b>
                      </th>
                      <td>{description}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </Card>
      <EditModel
        handleClose={handleClose}
        show={show}
        allDays={allDays}
        details={props.details}
        setAllDays={setAllDays}
        updateDayAndTime={props.updateDayAndTime}
        updateInfo={props.updateInfo}
      />
    </Home>
  );
};
const mapStateToProps = ({ information, successMassage }) => {
  return { details: information, successData: successMassage.data };
};
export default connect(mapStateToProps, {
  getInformation,
  updateAvailability,
  treeDriveAvailability,
  updateDayAndTime,
  updateInfo
})(Information);
